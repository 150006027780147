// Init
import { analytics } from "../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import compressor from "./compressor";

// handle input
const handleInput = (e, setInvoice, invoice) => {
  // Checking if user uploaded an image
  if (e.target.name === "transaction_ss") {
    const file = e.target.files[0]; // Getting Image

    // Compressing
    compressor(file)
      .then((image) => {
        // Appending image to form data
        const formData = new FormData();
        formData.append("image", image);
        formData.append("invoice_id", invoice._id);

        // Upload Process
        axios
          .post("/images/invoices", formData)
          .then((res) => {
            toast.success("Image updated successfully.");
            // setting url to invoice
            setInvoice((prev) => {
              return {
                ...prev,
                transaction_ss: res.data.url,
              };
            });
          })
          .catch(() => {
            toast.error("Could not update image");
          });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setInvoice((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Submit
const handleSubmit = (e, invoice, setInvoice, setEditing, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Update Request
  axios
    .put("/invoices", invoice)
    .then((res) => {
      // If success
      setEditing(false);
      setLoading(false);
      setInvoice(res.data);
      toast.success("Invoice updated successfully.");

      // Logging analytics
      analytics.logEvent("Invoices Updated");
    })
    .catch((err) => {
      // If session expired then redirect lo login page
      if (err.response.status === 401) {
        window.location = "/login";
        toast.error(err.response.data);
      } else if (err.response.status === 400) {
        toast.error(err.response.data);
        setLoading(false);
      } else {
        toast.error("Something went wrong.");
        setLoading(false);
      }
    });
};

// Export
export { handleInput, handleSubmit };
