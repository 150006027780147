import Compress from "browser-image-compression";


const compressor =async (file) => {
  // Compression config
  const options = {
    maxSizeMB: 2, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  };

  try {
    const compressedBlob = await Compress(file, options);

    // Adding metadeta
    compressedBlob.lastModifiedDate = new Date();

    // Convert the blob to file
    return new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now(),
    });
  } catch (error) {
    console.log('Image compress error: ', error);
    throw error;
  }
}


export default compressor;
