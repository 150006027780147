// Init
import { analytics } from "../firebase";
import axios from "axios";
import { toast } from "react-toastify";
import compressor from "./compressor";

// handle input
const handleInput = (e, setProduct, product) => {
  // Checking if user uploaded an image
  if (e.target.name === "amazon_pic" || e.target.name === "pic") {
    const file = e.target.files[0]; // Getting Image

    // Compressing
    compressor(file)
      .then((image) => {
        // Adding image to form data
        const formData = new FormData();
        formData.append("image", image);
        formData.append("product_id", product._id);
        formData.append("image_name", e.target.name);

        // Upload Process
        axios
          .post(`/images/products/${e.target.name}s`, formData)
          .then((res) => {
            toast.success("Image updated successfully!");
            // setting url to product
            setProduct((prev) => {
              return {
                ...prev,
                [e.target.name]: res.data.url,
              };
            });
          })
          .catch(() => {
            toast.error("Could not update the image.");
          });
      })
      .catch((e) => {
        // Show the user a toast message or notification that something went wrong while compressing file
      });
  } else {
    // Input
    setProduct((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }
};

// Handle Submit
const handleSubmit = (e, product, setEditing, setLoading) => {
  e.preventDefault();
  setLoading(true);

  // Checking if sale limit lesser than overall limit
  if (parseInt(product.sale_limit) > parseInt(product.overall_sale_limit)) {
    toast.error("Sale limit must be lesser than overall limit...");
    setLoading(false);
  } else {
    // getting the product to check latest updates
    axios
      .put("/products", product)
      .then(() => {
        // If success
        setEditing(false);
        setLoading(false);
        toast.success("Product updated successfully.");

        // Logging analytics
        analytics.logEvent("Products Updated");
      })
      .catch((err) => {
        // If session expired then redirect lo login page
        if (err.response.status === 401) {
          window.location = "/login";
          toast.error(err.response.data);
        } else if (err.response.status === 400 || err.response.status === 403) {
          toast.error(err.response.data);
          setLoading(false);
        } else if (err.response.status === 422) {
          toast.error(err.response.data);
          setLoading(false);
        } else {
          toast.error("Something went wrong.");
          setLoading(false);
        }
      });
  }
};

// Export
export { handleInput, handleSubmit };
